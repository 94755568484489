<template>
    <div class="mx-auto mt-6" style="max-width:400px;">
        <v-btn
            class="primary--text mx-auto"
            color="#212121"
            dark
            to="/"
            block>
            לעוד תפילות לחצו כאן
        </v-btn>
    </div>
</template>

<script>

export default {
    
}

</script>